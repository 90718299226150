import React from "react";
import "./Footer.css"
import ProductFooter from "../Products/ProductItem/ProductFooter"

export default function Footer(){

    return (
        <div className={"footer-wrapper"}>
            <ProductFooter mode={"dark"} />
            <div className={"footer-content"}>
            <div className={"footer-info-d"}>Fredrikssons Strumpfabrik HB - Idrottsgatan 3, 514 70 Grimsås </div>
            <div className={"footer-info-m"}>Fredrikssons Strumpfabrik HB <br /> Idrottsgatan 3, 514 70 Grimsås </div>
            <div className={"footer-signature"}>© 2021 Niklas Paasonen</div>
            </div>
        </div>
    );
}