import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Navbar from "./components/Navbar/Navbar.js";
import Home from "./components/Home/Home.js";
import Products from "./components/Products/Products";
import About from "./components/About/About";
import Contact from "./components/Contact/Contact";
import Footer from "./components/Footer/Footer";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";

import "./styles/main.css";

import firebase from "firebase";
import config from "./config";

function App() {
  if (!firebase.apps.length) {
    firebase.initializeApp(config);
    firebase.analytics();
    firebase.analytics().setCurrentScreen(window.location.pathname); // sets `screen_name` parameter
    firebase.analytics().logEvent("screen_view"); // log event with `screen_name` parameter attached
  }
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <main>
            <Navbar Firebase={firebase} />
            <Home />
            <Products />
            <About />
            <Contact Firebase={firebase} />
            <Footer />
          </main>
        </Route>

        {/* Catch all */}

        <Redirect to={"/"} />
      </Switch>
    </Router>
  );
}

export default App;
