import React, { useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import "./Sizes.css";

export default function Sizes(props) {
  const [open, setOpen] = useState(false);

  return (
    <Accordion>
      <Card className={"sizes-card"}>
        <Accordion.Toggle
          as={Card.Header}
          eventKey="0"
          className={"sizes-header"}
          onClick={() => setOpen(!open)}
        >
          <div className={"toggle-btn"}>
            <div className={`plus ${open ? "opened" : "closed"}`}>
              <div className={"horizontal"}></div>
              <div className={"vertical"}></div>
            </div>
            <div>Storlekar</div>
          </div>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="0">
          <Card.Body className={"sizes-body"}>
            <div className={"item-sizes"}>
              <div className={"sizes1"}>
                {props.product.sizeType1.map(size => {
                  return (
                    <span key={size}>
                      {size}&nbsp;
                      <br />
                    </span>
                  );
                })}
              </div>
              <div className={"sizes2"}>
                {props.product.sizeType2.map(size => {
                  return (
                    <span key={size}>
                      {size}&nbsp;
                      <br />
                    </span>
                  );
                })}
              </div>
            </div>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
}
