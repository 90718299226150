import React, { useEffect, useState } from "react";
import "./Products.css";
import ProductItem from "./ProductItem/ProductItem";
import db from "../../Items.json";

export default function Products() {
  const [products, setProducts] = useState(null);

  useEffect(() => {
    setProducts(db.items);
  }, []);

  return (
    <div className={"products-wrapper"}>
      <div className={"products-anchor"} id={"products"} />
      <h1>VÅRA PRODUKTER</h1>

      {products &&
        products.length > 0 &&
        products.map((product, i) => {
          return (
            <ProductItem
              key={product.id}
              image={`${process.env.PUBLIC_URL}/${product.id}.webp`}
              align={i % 2 === 0 ? "left" : "right"}
              product={product}
            />
          );
        })}
    </div>
  );
}
