const config = {
    apiKey: "AIzaSyAYfFjJtashJN0SBgbc63nl54fbZCP-YEg",
    authDomain: "fredrikssons-strumpfabrik.firebaseapp.com",
    databaseURL: "https://fredrikssons-strumpfabrik.firebaseio.com",
    projectId: "fredrikssons-strumpfabrik",
    storageBucket: "fredrikssons-strumpfabrik.appspot.com",
    messagingSenderId: "594078864530",
    appId: "1:594078864530:web:3b3bce327c0583643cc24b",
    measurementId: "G-6DX0V3V44B"
  };

export default config;