import React, { useState, useEffect } from "react";
import "./Navbar.css";
import Logo from "../../images/logo.webp";
import smoothscroll from "smoothscroll-polyfill";

export default function Navbar(props) {
  const [offsetY, setOffsetY] = useState(0);
  const handleScroll = () => setOffsetY(window.pageYOffset);
  const limitY = 120;

  /* Add event listener for scrolling to change color of navbar from transparent */
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    smoothscroll.polyfill();
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  /* Scroll to specified div */
  const handleClick = event => {
    event.preventDefault();
    const targetDivId = event.target.id.slice(0, -4);
    props.Firebase.analytics().logEvent("navbar_" + targetDivId);

    const anchorTarget = document.getElementById(targetDivId);

    targetDivId === "home"
      ? window.scroll({ top: 0, behavior: "smooth" })
      : anchorTarget.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <nav
      className={"navbar-wrapper"}
      style={
        offsetY > limitY
          ? {
              backgroundColor: "#f6f6f6",
              boxShadow: "0px 0px 15px 5px #52525221"
            }
          : { backgroundColor: "transparent", boxShadow: "none" }
      }
    >
      <img
        onClick={handleClick}
        id={"homeLink"}
        src={Logo}
        className={`navbar-logo ${offsetY > limitY ? "logo-minimize" : ""}`}
        alt={"fredrikssons logotyp"}
      />
      <div
        className={`navbar-links ${
          offsetY > limitY ? "navbar-links-minimize" : ""
        } `}
      >
        <a
          onClick={handleClick}
          id={"productsLink"}
          aria-label={"Rulla ned till Våra produkter"}
          href={"#vara-produkter"}
          className={`nav-link nav-link-desktop ${
            offsetY < limitY ? "nav-link-border" : ""
          }`}
          style={{ color: offsetY > limitY ? "#21292E" : "#f6f6f6" }}
        >
          VÅRA PRODUKTER
        </a>
        <a
          onClick={handleClick}
          id={"productsLink"}
          aria-label={"Rulla ned till Våra produkter"}
          href={"#vara-produkter"}
          className={"nav-link nav-link-mobile"}
          style={{ color: offsetY > limitY ? "#21292E" : "#f6f6f6" }}
        >
          PRODUKTER
        </a>

        <a
          onClick={handleClick}
          id={"aboutLink"}
          aria-label={"Rulla ned till Om oss"}
          href={"#om-oss"}
          className={`nav-link ${offsetY < limitY ? "nav-link-border" : ""}`}
          style={{ color: offsetY > limitY ? "#21292E" : "#f6f6f6" }}
        >
          OM OSS
        </a>
        <a
          onClick={handleClick}
          id={"contactLink"}
          aria-label={"Rulla ned till Kontakt"}
          href={"#kontakt"}
          className={`nav-link ${offsetY < limitY ? "nav-link-border" : ""}`}
          style={{ color: offsetY > limitY ? "#21292E" : "#f6f6f6" }}
        >
          KONTAKT
        </a>
      </div>
    </nav>
  );
}
