import React from "react";
import "./ProductItem.css"
import { FaSocks } from "react-icons/fa"

export default function ProductFooter(props){

    return (
        <div className={"item-footer"}>
           <div className={"item-footer-line"} style={props.mode === "dark" ? {borderColor: "#21292e"} : {}} />
            <FaSocks className={"item-footer-icon"} style={props.mode === "dark" ? {color: "#21292e"} : {}} />
           <div className={"item-footer-line"} style={props.mode === "dark" ? {borderColor: "#21292e"} : {}} /> 
        </div>
    );
}