import React, { useEffect, useRef } from "react";
import "./Home.css";

export default function Home() {
  const home = useRef(null);
  const content = useRef(null);
  /* Add event listener for scrolling for parallax effecg */
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleScroll = () => {
    var scrolled = window.pageYOffset;
    var rateImg = scrolled * 0.5;
    var rateContent = scrolled * -0.1;
    home.current.style.transform = "translate3D(0px, " + rateImg + "px, 0px)";
    content.current.style.transform =
      "translate3D( 0px, " + rateContent + "px, 0px)";
  };
  return (
    <div className={"home"} id={"home"} ref={home}>
      <div className={"home-content"} ref={content}>
        <h1 className={"home-title"}>FREDRIKSSONS</h1>
        <div className={"home-subtitle"}>GENUIN SVENSK KVALITET</div>
      </div>
    </div>
  );
}
