import React from "react";
import "./Contact.css";

export default function Contact(props) {
  const handleClick = (event) => {
    const targetDivId = event.target.id;
    props.Firebase.analytics().logEvent(targetDivId);
  };
  return (
    <div className={"contact-wrapper"}>
      <div className={"contact-anchor"} id={"contact"} />
      <h1>KONTAKT</h1>
      <p>
        För beställning, frågor eller andra ärenden vänligen kontakta oss via
        e-post eller på telefon.
      </p>
      <br />
      <h4>E-post</h4>
      <a
        id={"mail_link"}
        onClick={handleClick}
        className={"contact-link"}
        href="mailto:fredrikssons.strumpfabrik@telia.com "
      >
        fredrikssons.strumpfabrik@telia.com
      </a>
      <br />
      <br />
      <h4>Telefon</h4>
      <a
        id={"phone_link"}
        onClick={handleClick}
        className={"contact-link"}
        href="tel:032574204"
      >
        0325 - 74 204
      </a>
      <br />
      <br />
      <h4>Besöksadress</h4>
      <p>Storgatan 20, 514 70 Grimsås</p>
    </div>
  );
}
