import React, { useState, useEffect, useRef } from "react";
import "./ProductItem.css";
import ProductFooter from "./ProductFooter";
import Sizes from "./Sizes";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

export default function ProductItem(props) {
  const [isVisible, setIsVisible] = useState(false);
  const itemSection = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting) {
        setIsVisible(true);

        observer.unobserve(itemSection.current);
      }
    });

    observer.observe(itemSection.current);

    return () => observer.unobserve(itemSection.current);
  }, []);

  const renderTooltip = color => <Tooltip id="button-tooltip">{color}</Tooltip>;

  return (
    <>
      <div
        ref={itemSection}
        className={`item-wrapper ${
          props.align === "left" ? "item-align-left" : "item-align-right"
        }
          ${isVisible ? "is-visible" : ""}
        `}
      >
        <div
          className={
            props.align === "left" ? "item-padding-right" : "item-padding-left"
          }
        >
          <div className={"item-image-wrapper-mobile"}>
            <img
              src={props.image}
              className={"item-image"}
              alt={"Bild på " + props.title}
            />
          </div>
          <div className={"item-title"}>
            <h3>{props.product.title}</h3>
          </div>

          <div className={"item-order-number"}>Art nr. {props.product.id}</div>

          <div className={"item-stats"}>
            {props.product.stats.map(stat => {
              return (
                <span key={stat}>
                  {stat}
                  <br />
                </span>
              );
            })}
          </div>

          <div className={"item-colors"}>
            {props.product.colors.map(c => {
              return (
                <OverlayTrigger
                  key={c}
                  placement="bottom"
                  delay={{ show: 250, hide: 400 }}
                  overlay={renderTooltip(c)}
                >
                  <div className={`item-color ${c}`} />
                </OverlayTrigger>
              );
            })}
          </div>

          <Sizes product={props.product} />

          <div className={"item-description"}>{props.product.description}</div>
        </div>
        <div className={"item-image-wrapper-desktop"}>
          <img
            src={props.image}
            className={"item-image"}
            alt={"Bild på " + props.title}
          />
        </div>
      </div>
      <ProductFooter />
    </>
  );
}
