import React from "react";
import "./About.css"
import ProductFooter from "../Products/ProductItem/ProductFooter"
export default function About(){

    return (
        <>
        <div className={"about-anchor"} id={"about"}/>
        <div className={"about-bg"} />
            
        <div className={"about-card"}>
            <h1>OM OSS</h1>
            <br />
            <p>Vi som driver Fredrikssons Strumpfabrik heter Sture och Tuula Fredriksson. Vi slog upp dörrarna till vår fabrik år 1997 och sedan dess har vi levererat specialbeställda strumpor till privatpersoner och företag. </p>
            
            <p>Tillverkningen sker i Grimsås och vi är stolta över att ha full kontroll över hela processen där kvaliteten alltid är i fokus. </p>
             <br />
            <p style={{margin: "0px"}}><b>Låt oss ta hand om era fötter!</b></p>
            
            <ProductFooter />
            
            
        </div>
        </>
    );
}